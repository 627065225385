import { RESET_STORE } from '@connectors/actions';
import ReduxStatus from '@enums/ReduxStatus';
import { ReduxAction } from 'global';

import {
  ANALYTICS_GET_FAILURE,
  BROWSERS_ANALYTICS_GET,
  BROWSERS_ANALYTICS_GET_SUCCESS,
  CAMPAIGNS_FOR_ANALYTICS_GET_SUCCESS,
  DEVICES_ANALYTICS_GET,
  DEVICES_ANALYTICS_GET_SUCCESS,
  RESOLUTIONS_ANALYTICS_GET,
  RESOLUTIONS_ANALYTICS_GET_SUCCESS,
  CLEAR_ANALYTICS_CAMPAIGN,
  CLEAR_ANALYTICS_CAMPAIGN_SUCCESS,
} from './constants';
import produce, { Draft } from 'immer';

export interface Statistic {
  impressionCount: number;
  pageViewCount: number;
  interactionCount: number;
  displayCount: number;
  lastDaysImpressionCount: number;
  lastMonthsImpressionCount: number;
  lastMonthsPageViewCount: number;
  rate: string;
}

export interface Campaign {
  id: number;
  name: string;
  imageUrl: string;
  domains?: string[];
}

// TODO remove after all usages are deleted
export interface ShortAnalytics {
  pageViews: number;
  fiveDaysEarlyPageViews: number;
  pageViewPercent: number;
  popupViews: number;
  fiveDaysEarlyPopupViews: number;
  popupViewPercent: number;
  leads: number;
  fiveDaysEarlyLeads: number;
  leadPercent: number;
  clicks: number;
  fiveDaysEarlyClicks: number;
  clickPercent: number;
  convensionRate: number;
  conversionRateIncriment: number;
}

export interface Devices {
  device: string;
  count: number;
  rate: number;
}

export interface Resolutions {
  resolution: string;
  count: number;
  rate: number;
}

export interface Browser {
  browser: string;
  count: number;
  rate: number;
}

export interface AnalyticsState {
  status: ReduxStatus;
  devices: {
    status: ReduxStatus;
    data: Devices[];
  };
  resolutions: {
    status: ReduxStatus;
    data: Resolutions[];
  };
  browsers: {
    status: ReduxStatus;
    data: Browser[];
  };
  campaigns: {
    status: ReduxStatus;
    data: Campaign[];
  };
}

export const initialState: AnalyticsState = {
  status: ReduxStatus.loading,
  campaigns: {
    status: ReduxStatus.loading,
    data: [],
  },
  devices: {
    status: ReduxStatus.loading,
    data: [],
  },
  resolutions: {
    status: ReduxStatus.loading,
    data: [],
  },
  browsers: {
    status: ReduxStatus.initialized,
    data: [],
  },
};
const reducer = produce((draft: Draft<AnalyticsState>, action: ReduxAction) => {
  const { type, payload } = action;
  switch (type) {
    case CAMPAIGNS_FOR_ANALYTICS_GET_SUCCESS:
      draft.campaigns.data = payload;
      return draft;
    case DEVICES_ANALYTICS_GET:
      draft.devices.status = ReduxStatus.loading;
      return draft;
    case DEVICES_ANALYTICS_GET_SUCCESS:
      draft.devices.data = payload;
      return draft;
    case RESOLUTIONS_ANALYTICS_GET:
      draft.resolutions.status = ReduxStatus.loading;
      return draft;
    case RESOLUTIONS_ANALYTICS_GET_SUCCESS:
      draft.resolutions.data = payload;
      return draft;
    case BROWSERS_ANALYTICS_GET:
      draft.browsers.status = ReduxStatus.loading;
      return draft;
    case BROWSERS_ANALYTICS_GET_SUCCESS:
      draft.browsers.data = payload;
      return draft;
    case ANALYTICS_GET_FAILURE:
      draft.status = ReduxStatus.errored;
      return draft;
    case CLEAR_ANALYTICS_CAMPAIGN:
      draft.campaigns.data = [];
      return draft;
    case CLEAR_ANALYTICS_CAMPAIGN_SUCCESS:
      draft.campaigns.status = ReduxStatus.initialized;
      return draft;
    case RESET_STORE:
      return initialState;
    default:
      return draft;
  }
}, initialState);
export default reducer;
