import React, { FC } from 'react';

interface IconProps {
  width?: string;
  height?: string;
  viewBox?: string;
  className?: string;
}

const CloseIcon: FC<IconProps> = ({ width, height, viewBox, className }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox={viewBox || '0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.68531C4.79981 4.78412 3.51809 6.34591 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22.0012 10.6865 21.7433 9.38556 21.2412 8.17177C20.7391 6.95798 20.0025 5.85512 19.0737 4.9263C18.1449 3.99747 17.042 3.26093 15.8282 2.7588C14.6144 2.25667 13.3136 1.99882 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00347 15.0615 4.60897C16.5233 5.21447 17.7727 6.23985 18.6518 7.55544C19.5308 8.87104 20 10.4178 20 12C19.9971 14.1208 19.1533 16.154 17.6536 17.6536C16.154 19.1533 14.1208 19.9971 12 20Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;
